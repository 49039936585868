/* You can add global styles to this file, and also import other style files */

* {
  font-family: "Kanit" !important;
}

@font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit/Kanit-Black.woff2") format("woff2"),
    url("./assets/fonts/Kanit/Kanit-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit/Kanit-Bold.woff2") format("woff2"),
    url("./assets/fonts/Kanit/Kanit-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit/Kanit-BlackItalic.woff2") format("woff2"),
    url("./assets/fonts/Kanit/Kanit-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit/Kanit-ExtraLight.woff2") format("woff2"),
    url("./assets/fonts/Kanit/Kanit-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit/Kanit-ExtraBoldItalic.woff2") format("woff2"),
    url("./assets/fonts/Kanit/Kanit-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

/* @font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit/Kanit-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/Kanit/Kanit-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit/Kanit-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/Kanit/Kanit-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit/Kanit-Medium.woff2") format("woff2"),
    url("./assets/fonts/Kanit/Kanit-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit/Kanit-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/Kanit/Kanit-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("assets/fonts/Kanit/Kanit-Italic.woff2") format("woff2"),
    url("assets/fonts/Kanit/Kanit-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit/Kanit-ExtraLightItalic.woff2") format("woff2"),
    url("./assets/fonts/Kanit/Kanit-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit/Kanit-Thin.woff2") format("woff2"),
    url("./assets/fonts/Kanit/Kanit-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit/Kanit-Regular.woff2") format("woff2"),
    url("./assets/fonts/Kanit/Kanit-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit/Kanit-ThinItalic.woff2") format("woff2"),
    url("./assets/fonts/Kanit/Kanit-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit/Kanit-MediumItalic.woff2") format("woff2"),
    url("./assets/fonts/Kanit/Kanit-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit/Kanit-SemiBoldItalic.woff2") format("woff2"),
    url("./assets/fonts/Kanit/Kanit-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

button:focus {
  outline: none;
}

.font-base {
  font-size: 1.2rem;
}

.text-xl {
  font-size: 3rem;
}

.font-bold {
  font-weight: 700;
}

.mb-2px {
  margin-bottom: 2px !important;
}

.box-center {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.page-not-found-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  color: #666666;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  text-align: center;
}
